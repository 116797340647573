<template>
  <div class="bitts-breadcrumbs">
    <Breadcrumb>
      <template #separator>
        <FontAwesomeIcon :icon="['fas', 'chevron-right']" />
      </template>
      <BreadcrumbItem
        v-if="icon"
        class="bitts-breadcrumbs__icon bitts-breadcrumb"
        @click="$emit('root-clicked')"
      >
        <FontAwesomeIcon :icon="icon" />
      </BreadcrumbItem>
      <BreadcrumbItem
        v-for="crumb in beforeCrumbs"
        :key="crumb.name"
        :data-testid="`${crumb.name.toLowerCase()}-crumb`"
        class="bitts-breadcrumb bitts-breadcrumbs__item--before"
        @click="$emit('breadcrumb-clicked', crumb)"
      >
        {{ crumb.text }}
      </BreadcrumbItem>
      <BreadcrumbItem
        v-if="activeCrumb"
        :data-testid="`${activeCrumb.name.toLowerCase()}-crumb`"
        class="bitts-breadcrumb bitts-breadcrumbs__item--active"
      >
        {{ activeCrumb.text }}
      </BreadcrumbItem>
      <BreadcrumbItem
        v-for="crumb in afterCrumbs"
        :key="crumb.name"
        :data-testid="`${crumb.name.toLowerCase()}-crumb`"
        class="bitts-breadcrumb bitts-breadcrumbs__item--after"
        @click="$emit('breadcrumb-clicked', crumb)"
      >
        {{ crumb.text }}
      </BreadcrumbItem>
    </Breadcrumb>
  </div>
</template>

<script setup>
import { Breadcrumb, BreadcrumbItem } from 'ant-design-vue';
import { computed } from 'vue';

const props = defineProps({
  icon: {
    type: Array,
    default: () => ['far', 'house'],
    validator: (value) => value.length === 2,
  },
  crumbs: {
    type: Array,
    default: () => [],
    validator: (values) =>
      values.every((obj) => ['name', 'text'].every((key) => key in obj)),
  },
  activeCrumbIndex: {
    type: Number,
    required: true,
    validator: (value) => value >= 0,
  },
  lastCrumbIndex: {
    type: Number,
    required: true,
    validator: (value) => value >= 0,
  },
});

defineEmits(['breadcrumb-clicked', 'root-clicked']);
const icon = computed(() => props.icon);
const beforeCrumbs = computed(() =>
  props.crumbs.slice(0, props.activeCrumbIndex),
);
const activeCrumb = computed(() =>
  props.crumbs.length > 0 ? props.crumbs.at(props.activeCrumbIndex) : null,
);
const afterCrumbs = computed(() =>
  props.crumbs.slice(props.activeCrumbIndex + 1, props.lastCrumbIndex + 1),
);
</script>

<style lang="pcss">
.bitts-breadcrumbs {
  @apply leading-6;

  .bitts-breadcrumbs__icon {
    @apply text-neutral-accent;
  }

  .bitts-breadcrumbs__icon:hover {
    @apply text-secondary-accent cursor-pointer;
  }

  .bitts-breadcrumbs__item--before {
    @apply text-primary-text-link-hover cursor-pointer;
    &:hover {
      @apply text-secondary-accent;
    }
  }

  .bitts-breadcrumbs__item--after,
  .ant-breadcrumb li:last-child {
    @apply text-neutral-400;
  }

  .bitts-breadcrumbs__item--active,
  .ant-breadcrumb li:last-child.bitts-breadcrumbs__item--active {
    @apply text-neutral-text-weak;
  }

  .ant-breadcrumb {
    font-family: inherit;
  }

  .ant-breadcrumb-separator {
    @apply text-neutral-accent px-6 ms-0 me-0;
  }

  .bitts-breadcrumb:last-child {
    .ant-breadcrumb-separator {
      @apply hidden;
    }
  }
}
</style>
